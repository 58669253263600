import { useStaticQuery, graphql } from 'gatsby';

export const getCarouselImages = () => {
  const data = useStaticQuery(graphql`
    {
      one: file(relativePath: { eq: "main_carousel/1.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      two: file(relativePath: { eq: "main_carousel/2.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      three: file(relativePath: { eq: "main_carousel/3.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      four: file(relativePath: { eq: "main_carousel/4.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      five: file(relativePath: { eq: "main_carousel/5.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      six: file(relativePath: { eq: "main_carousel/6.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
    }
  `);

  return {
    main: {
      one: data.one.childImageSharp.gatsbyImageData,
      two: data.two.childImageSharp.gatsbyImageData,
      three: data.three.childImageSharp.gatsbyImageData,
      four: data.four.childImageSharp.gatsbyImageData,
      five: data.five.childImageSharp.gatsbyImageData,
      six: data.six.childImageSharp.gatsbyImageData,
    },
  };
};
