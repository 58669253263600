import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FaQuoteRight } from 'react-icons/fa';

export const Testimonials = () => {
  return (
    <Row>
      <Col>
        <Row>
          <Col className="ml-auto mr-auto d-flex justify-content-center">
            <h2 className="title text-center text-lg-start mt-0 mb-5 mt-lg-5">
              Testimonials
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg="2" />
          <Col lg="4">
            <Card className="card-testimonial">
              <Card.Body>
                <div className="card-icon">
                  <FaQuoteRight color="#4bbf73" />
                </div>
                <div className="clearfix" />
                <p className="card-description text-start">
                  &ldquo;Willy is an awesome skateboarding coach. He started
                  teaching our twins at age 4. His energy seems to be endless
                  and is always right next to kiddos keeping them safe while
                  still learning and having tons of fun. You can tell he enjoys
                  sharing his knowledge and the kids love being around him.
                  He&rsquo;s outgoing, safe, kind and a great person all
                  around.&rdquo;
                </p>
                <p className="text-start mt-3">Polly Fischer-Pool</p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-testimonial">
              <Card.Body>
                <div className="card-icon">
                  <FaQuoteRight color="#1f9bcf" />
                </div>
                <div className="clearfix" />
                <p className="card-description text-start">
                  &ldquo;Willy helped our 5 year-old learn the basics of
                  skating. His enthusiasm, kindness, and patience helped her
                  love the sport. She always asks when she can have another
                  lesson and is so excited to show us all the tricks that he has
                  taught her. We look forward to taking more lessons!&rdquo;
                </p>
                <p className="text-start mt-3">
                  Jennifer Fiedler, mom of Evie, age 5
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="2" />
        </Row>
        <Row>
          <Col />
          <Col className="mt-lg-4" lg="5">
            <Card className="card-testimonial">
              <Card.Body>
                <div className="card-icon">
                  <FaQuoteRight color="#d9534f" />
                </div>
                <div className="clearfix" />
                <p className="card-description text-start">
                  &ldquo;We have been working with Willy for over a year and a
                  half. When we first met Willy I needed to hold my daughters
                  hand while she was skateboarding, within a few weeks Willy
                  taught her to drop in on her own, and learn the basics which
                  she has been able to build on herself. Throughout the year
                  Willy has continued to help Zoee progress so much faster than
                  she would have without his guidance. His love for
                  skateboarding and his enthusiasm to help others is so
                  incredible , we are lucky to have found him. Zoee looks
                  forward to every session with Uncle Willy.&rdquo;
                </p>
                <p className="text-start mt-3">
                  Amanda Bradshaw, mom of Zoee age 6
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col />
        </Row>
      </Col>
    </Row>
  );
};
