import { useStaticQuery, graphql } from 'gatsby';

export const getHomeImages = () => {
  const data = useStaticQuery(graphql`
    {
      weeklySchedule: file(relativePath: { eq: "weekly_schedule.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      weekendSchedule: file(relativePath: { eq: "weekend_schedule.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      willy: file(relativePath: { eq: "willy.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
    }
  `);

  return {
    weeklySchedule: data.weeklySchedule.childImageSharp.gatsbyImageData,
    weekendSchedule: data.weekendSchedule.childImageSharp.gatsbyImageData,
    willy: data.willy.childImageSharp.gatsbyImageData,
  };
};
