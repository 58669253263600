import React from 'react';
import { Card, Carousel } from 'react-bootstrap';
import { getCarouselImages } from 'components/images/carousel_images';
import { GatsbyImage } from 'gatsby-plugin-image';

type MainCarouselProps = {
  source?: 'main' | 'about';
};

export const MainCarousel = ({ source = 'main' }: MainCarouselProps) => {
  const carouselItems = {
    main: [
      {
        src: getCarouselImages().main.one,
        altText: 'Young students dropping in with the help of an instructor',
      },
      {
        src: getCarouselImages().main.two,
        altText: 'Young students on a ramp',
      },
      {
        src: getCarouselImages().main.three,
        altText: 'Indoor ramps and quarter pipes',
      },
      {
        src: getCarouselImages().main.four,
        altText: 'Indoor bowl',
      },
      {
        src: getCarouselImages().main.five,
        altText: 'Skate shop',
      },
      {
        src: getCarouselImages().main.six,
        altText: 'At the Kahuku sugar mill',
      },
    ],
  };

  return (
    <Card className="card-raised page-carousel">
      <Carousel>
        {carouselItems[source].map((item, i) => {
          return (
            <Carousel.Item key={i.toString()}>
              <GatsbyImage image={item.src} alt={item.altText} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Card>
  );
};
