import React from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'gatsby';
import MainLogo from 'assets/images/logos/sunset_skates_1.svg';
import { SEO } from 'components/seo';
import { NavHeader } from 'components/nav_header';
import { MainCarousel } from 'components/main_carousel';
import { Footer } from 'components/footer';
import { Testimonials } from 'components/testimonials';
import { getHomeImages } from 'components/images/home_images';
import { GatsbyImage } from 'gatsby-plugin-image';

const IndexPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const logo = (
    <img
      src={MainLogo}
      alt="Sunset Skates"
      className="img-rounded img-responsive"
    />
  );

  const { willy } = getHomeImages();

  return (
    <>
      <SEO pageTitle="Home" isHomePage />
      <div className="d-flex flex-column h-100">
        <NavHeader />
        <main className="main">
          <div className="wrapper bg-white">
            <div className="content-center">
              <Container style={{ marginTop: 140, marginBottom: 100 }}>
                <Row>
                  <Col
                    className="ms-auto me-auto mt-lg-5 d-flex justify-content-center"
                    lg="8"
                  >
                    <MainCarousel />
                  </Col>
                  <Col
                    className="ms-auto mb-5 m-lg-0 d-flex flex-column justify-content-center align-items-center align-items-lg-start"
                    lg="4"
                  >
                    <h2 className="text-center mt-lg-5 mb-lg-5">
                      Learn to Skate
                    </h2>
                    <Link to="/lessons">
                      <Button
                        variant="danger"
                        className="text-center mt-5 mt-lg-0"
                        title="Sign up"
                      >
                        <i className="nc-icon nc-minimal-right me-1 font-weight-bold" />
                        Sign up for lessons
                      </Button>
                    </Link>
                    <h5
                      className="description text-center text-lg-start mt-5"
                      style={{ color: '#555c62' }}
                    >
                      Personalized, individual and group skateboarding lessons
                      for all ages
                    </h5>
                    <br />
                  </Col>
                </Row>
                <Card className="card-plain card-blog">
                  <Row className="mb-lg-4">
                    <Col
                      lg="7"
                      className="d-flex align-items-center mb-5 mb-lg-0 order-2 order-lg-1"
                    >
                      <Card.Body>
                        <Card.Title as="h3" className="ps-4">
                          Sunset Skates
                        </Card.Title>
                        <p className="card-description ps-4">
                          Sunset Skates is the only Indoor Skatepark, Skate
                          School, and Skate Shop on the North Shore of Oahu,
                          Hawaii. We are located at the{' '}
                          <a
                            href="https://goo.gl/maps/U4zrq36iz4MmgvzM7"
                            target="_blank"
                            rel="noreferrer"
                            className="text-info"
                          >
                            Kahuku Sugar Mill at 56-565 Kamehameha Hwy Kahuku,
                            HI 96731
                          </a>
                        </p>
                        <p className="card-description ps-4">
                          Our skate lessons will benefit the complete beginner
                          by giving them the skills and confidence they need to
                          safely learn how to skateboard. We also help build the
                          skills of more advanced students by encouraging
                          creativity and independent style.
                        </p>
                      </Card.Body>
                    </Col>
                    <Col lg="1" className="order-1 order-lg-2" />
                    <Col lg="3" className="my-4 my-lg-0 order-1 order-lg-2">
                      <div className="card-image px-4 px-lg-0">{logo}</div>
                    </Col>
                    <Col lg="1" className="order-1 order-lg-2" />
                  </Row>
                </Card>
                <Row>
                  <Col
                    lg="5"
                    className="mt-lg-5 d-flex justify-content-center align-items-center"
                  >
                    <GatsbyImage
                      alt="Willy Akers"
                      className="img"
                      image={willy}
                    />
                  </Col>
                  <Col
                    lg="7"
                    className="d-flex align-items-center mb-5 mb-lg-0"
                  >
                    <Card.Body>
                      <Card.Title as="h3" className="ps-4">
                        Willy Akers
                      </Card.Title>
                      <p className="card-description ps-4 text-primary">
                        Willy Akers is the lead instructor at Sunset Skates.
                        Willy has 25 years of skateboarding experience. Once a
                        professional skateboarder, skateboarding has taken him
                        around the world eventually landing him in Sunset Beach
                        on the north shore of Oahu. Willy has a bachelor of fine
                        arts with a focus on education. His art background gives
                        him a unique and creative approach to skateboarding and
                        teaching. He is also a natural athlete able to teach
                        both the art and athleticism of skateboarding.
                      </p>
                      <p className="card-description ps-4 text-primary">
                        Willy really notices and focuses on his students
                        individual strengths building his students confidence
                        and individual style. Willy has experience teaching at
                        skateboarding camps such as Woodward and after school
                        programs in New York City. He is passionate about
                        sharing his love of skateboarding!
                      </p>
                    </Card.Body>
                  </Col>
                </Row>
                <Testimonials />
              </Container>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default IndexPage;
